<template>
	<construction-page
		v-bind:text-title='textTitle'
		v-bind:image-play="imagePlay"
		v-bind:image-mark="imageMark"
		v-bind:image-background="imageBackground"
		v-bind:color-main="colorMain"
		v-bind:color-text="colorText"
	/>
</template>

<script>
import constructionPage from "@/components/constructionPage.vue"

export default {
	components: {constructionPage},
	mounted() {
		this.$vuetify.theme.dark = false;
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = true;
	},
	methods: {
		goToGuide() {
			window.location.href = 'https://bit.ly/3a22CYm';
		},
	},
	computed: {
		imagePlay() {
			return require("@/assets/dongwon/play_color.svg");
		},
		imageMark() {
			return require("@/assets/dongwon/btn_color.svg");
		},
		imageBackground() {
			return require("@/assets/hoban/chaedle_pc.png");
		},
		textTitle() {
			return `동원개발이<br/>이 제공하는<br />
    <span style="color:#7a0939">특별한 경험</span>을<br />
    시작해 보세요.`;
		},
		colorMain() {
			return "#fff";
		},
		colorText() {
			return "#000";
		}
	},
};
</script>
