<template>
	<div id="container" @click="goToGuide">
		<main v-bind:style="styleMain">
			<div class="section_left">
				<h2 class="text_title" v-bind:style="styleTextTitle">스마트 라이프 스타일 앱</h2>
				<h1 class="text_content" v-html="textTitle" />
				<span class="text_footer">채들과 함께 합니다.</span>
			</div>
			<div class="section_right">
				<div class="container_guide">
					<img v-bind:src="imagePlay" alt="Guide" />
					<span class="text_guide" v-bind:style="styleGuide">가이드 보기</span>
				</div>
				<div class="footer">
					<img v-bind:src="imageMark" alt="Hoban On" />
				</div>
			</div>
		</main>
	</div>
</template>
<script>
export default {
	mounted() {
		this.$vuetify.theme.dark = false;
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = true;
	},
	methods: {
		goToGuide() {
			window.location.href = 'https://bit.ly/3a22CYm';
		},
	},
	computed: {
		styleMain() {
			return `background-image: url(${this.imageBackground});background-color: ${this.colorMain}`;
		},
		styleGuide() {
			return `color: ${this.colorText}`
		},
		styleTextTitle() {
			return `color: ${this.colorTextTitle}`
		}
	},
	props:
	{
		imagePlay:
		{
			type: String,
			default: null
		},
		imageMark:
		{
			type: String,
			default: null
		},
		imageBackground:
		{
			type: String,
			default: null
		},
		textTitle:
		{
			type: String,
			default: null
		},
		colorMain:
		{
			type: String,
			default: null
		},
		colorText:
		{
			type: String,
			default: null
		},
		colorTextTitle:
		{
			type: String,
			default: "#000"
		}
	}
};
</script>
<style scoped>
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

#container {
	font-family: 'Pretendard', sans-serif;
}

/* Main layout */
main {
	color: #000;
	width: 960px;
	height: 840px;
	border-radius: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 80px;
	display: flex;
	justify-content: space-between;
	border: 1px solid #e6e6e6;
	background-repeat: no-repeat;
	background-position: -160px center;
	background-size: 70%;
}

/* Left section */
.section_left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.section_left .text_title {
	font-size: 40px;
	font-weight: 500;
}

.section_left .text_content {
	color: #000 !important;
	margin-top: 55px;
	font-size: 60px;
	font-weight: 900;
	line-height: 80px;
	padding-bottom: 180px;
}

.section_left .text_footer {
	font-size: 24px;
	font-weight: 600;
	line-height: 80px;
	color: #ddd;
}

/* Right section */
.section_right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.section_right img {
	width: 160px;
	height: 164px;
}

.container_guide {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
	color: #fff;
}

.container_guide img {
	width: 32px !important;
	height: 32px !important;
}

.text_guide {
	font-size: 20px;
	font-weight: 500;
	margin-left: 12px;
}

/* Responsive styling */
@media (max-width: 768px) {
	main {
		width: 100%;
		height: 100%;
		padding: 40px;
		background-size: 100%;
	}
	
	.section_right {
		height: 100%;
		margin-top: 20px;
		position: absolute;
		right: 20px; /* Right alignment */
	}
	
	.section_right > .footer {
		position: relative;
		bottom: 10%;
	}
	
	.section_left .text_title {
		font-size: 24px;
		font-weight: 500;
		position: relative;
		top: 25%;
	}
	
	.section_left .text_content {
		margin-top: 20px;
		font-size: 36px;
		line-height: 50px;
		padding-bottom: 40px;
	}
	
	.section_left .text_footer {
		font-size: 18px;
		line-height: 50px;
		font-weight: normal;
	}
	
	.container_guide img {
		width: 80px;
		height: 80px;
	}
	
	.text_guide {
		font-size: 18px;
		margin-left: 8px;
	}
	
	.footer
	{
		display: flex;
		align-items: center;
		justify-content: center;
		width: auto;  /* 이미지 비율에 맞게 조정 */
		height: auto;
	}
	
	.footer img {
		max-width: 150px;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
</style>
